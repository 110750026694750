<template>
  <div class="footer">
    <div class="top-bar">
      <div>
        <span>About us</span>
        <p>Alpan Car Rentals<br>
        A.S. Alpan Car Rentals ltd, located in Paphos, Cyprus,<br>
        promises to provide you the lowest rental rates and superior<br>
        rental experience with the best price guarantee policy and<br>
        24/7 online customer service.</p>
      </div>
      <div class="center">
        <span>Opening hours</span>
        <p>Monday-Friday: 10:00 – 14:00 | 15:30 – 20:00</p>
        <p>Saturday: 10:00 – 14:00</p>
        <p>Sunday: Closed</p>
      </div>
      <div class="right">
        <span>Follow us</span>
        <div class="links">
          <a class="icon-facebook"></a>
          <a class="icon-instagram"></a>
        </div>
      </div>
    </div>
    <div class="bottom-bar">
      <span>A.S. Alpan Car Rentals Ltd. ©2021.</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
  .footer {
    background-color: #000000;
    display: grid;
    grid-template-rows: 88% 12%;
    width: 100%;
    max-width: 100vw;
  }
  .footer > * {
    padding: 0 15%;
    color: #ffffff;
    font-family: "Raleway", sans-serif;
  }
  .footer > *:not(.bottom-bar) {
    font-weight: 300;
  }
  .top-bar {
    display: flex;
    padding: 40px 15%;
  }
  .top-bar > * {
    min-width: 33%;
    max-width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-top: 2.5rem;
  }
  /*.top-bar > .center {*/
  /*  justify-content: flex-start;*/
  /*}*/
  .top-bar span {
    width: 100%;
    font-size: 1.4rem;
    font-weight: 500;
    position: absolute;
    top: 0;
  }
  .top-bar p {
    width: 100%;
    margin: 0;
    font-size: 0.8rem;
  }
  .top-bar .center {
    border-right: 1px solid rgba(255,255,255,0.6);
    border-left: 1px solid rgba(255,255,255,0.6);
  }
  .bottom-bar {
    background-color: rgb(38, 34, 98);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .links {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .links * {
    margin: 0 10px;
    background-color: orange;
    color: #000000;
    width: 3rem;
    height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
  }
  .links *::before {
    font-size: 3rem;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .top-bar {
      padding: 40px 2.5%;
    }
    .top-bar p {
      font-size: 0.6rem!important;
    }
  }
  @media screen and (max-width: 767px){
    .footer {
      /*height: 15rem;*/
    }
    .top-bar {
      padding: 40px 2.5%;
    }
    .top-bar > div {
      min-width: calc(33% - 20px);
      max-width: calc(33% - 20px);
      padding: 2rem 10px 0 10px;
    }
    /*.top-bar > div > * {*/
    /*  margin-left: -10px;*/
    /*}*/
    .top-bar span {
      font-size: 0.8rem;
      margin-left: -10px;
    }
    .top-bar p {
      font-size: 0.5rem!important;
    }
    .links * {
      width: 2rem;
      height: 2rem;
    }
    .links *::before {
      font-size: 2rem;
    }
    .bottom-bar {
      font-size: 0.8rem;
    }
  }
</style>