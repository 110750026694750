import Vue from 'vue'
import Vuex from 'vuex'

import http from "../services/http";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    initialized: false,
    locationOrigin: '',
    siteId: 0,
    siteIcon: '',
    cars: [],
    brands: [],
    contacts: {},
    users_comments: []
  },
  getters: {
    locationOrigin: state => {return state.locationOrigin},
    siteId: state => {return state.siteId},
    siteIcon: state => { return state.siteIcon},
    siteData: state => {
      return {
        cars: state.cars,
        brands: state.brands,
        contacts: state.contacts,
        comments: state.users_comments
      }
    },
    car: state => {
      return function (id) {
        const ID = parseInt(id)
        if (isNaN(id)) return {}
        const cars = state.cars
        for (let i = 0; i < cars.length; i++) {
          if (ID === cars[i].id) return cars[i]
          if (i === cars.length - 1) return {}
        }
      }
    }
  },
  mutations: {
    initialized(state, payload) {
      state.initialized = payload
    },
    locationOrigin(state, payload) {
      state.locationOrigin = payload
    },
    siteId(state, payload) {
      state.siteId = payload
    },
    siteIcon(state, payload) {
      state.siteIcon = payload
    },
    cars(state, payload) {
      state.cars = payload
    },
    brands(state, payload) {
      state.brands = payload
    },
    contacts(state, payload) {
      state.contacts = payload
    },
    comments(state, payload) {
      state.users_comments = payload
    }
  },
  actions: {
    setLocation({commit}, payload) {
      commit('locationOrigin', payload)
      http.getSiteData(payload).then(r => {
        console.log('SITE DATA: ', r)
        if (!r.data.error) {
          (() => {
            return new Promise(resolve => {
              if (r.data.id) commit('siteId', r.data.id)
              if (r.data.icon) commit('siteIcon', r.data.icon)
              if (r.data.users_comments) commit('comments', r.data.users_comments)
              if (r.data.cars) {
                commit('cars', r.data.cars)
                resolve(undefined)
              } else resolve(undefined)
            })
          })().then(() => {
            return new Promise(resolve => {
              if (r.data.brands) {
                commit('brands', r.data.brands)
                resolve(undefined)
              } else resolve(undefined)
            })
          }).then(() => {
            if (r.data.contact) {
              commit('contacts', r.data.contact)
              commit('initialized', true)
              console.info('storage initialized', JSON.stringify(this.state))
            }
          })
        }
      }).catch(e => {
        console.error(e)
      })
    },
    isReady() {
      return new Promise(resolve => {
        let attempts = 0
        const int = setInterval(() => {
          if (this.state.initialized) {
            // alert('INITED')
            clearInterval(int)
            resolve(true)
          } else {
            attempts++
            if (attempts >= 20) {
              // alert('NOT INITED')
              clearInterval(int)
              resolve(false)
            }
          }
        }, 500)
      })
    }
  },
  modules: {
  }
})
