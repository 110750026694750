<template>
  <div class="brands">
    <span>Vehicle brands</span>
    <hr>
    <span>We work with following brands</span>
    <div class="slider">
<!--      <img src="../assets/img_1.png" alt="">-->
<!--      <img src="../assets/img_2.png" alt="">-->
      <img :src="getImageSrc(b.image, 'brands')" alt="" v-for="(b, i) in brands" :key="i" :class="{'active': i === 1}">
    </div>
  </div>
</template>

<script>
import http from "../services/http";

export default {
  name: "Brands",
  props: {
    brands: {type: Array, default: () => []}
  },
  methods: {
    getImageSrc(image, key) {
      return http.getImageSrc(image, key);
    }
  }
}
</script>

<style scoped>
.brands {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  padding: 20px;
  max-width: calc(100% - 40px);
}
.brands * {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 300;
}
.brands > *:not(:first-child) {
  margin-top: 20px;
}
.brands > span:first-child {
  font-size: 1.8rem;
}
.brands > span:not(:first-child) {
  font-weight: 400;
}
.brands hr {
  height: 2px;
  color: #ffce07;
  background-color: #ffce07;
  border: none;
  width: 5%;
}
.brands .slider {
  /*width: 90%;*/
  /*margin: 30px auto 0;*/
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}
.brands .slider > img {
  /*margin: auto;*/
  width: 140px;
  height: 140px;
  /*margin: 0 40px;*/
  border-radius: 50%;
  /*border: 1px solid #ffffff;*/
}
/*.brands .slider > img.active {*/
/*  transform: scale(1.2);*/
/*}*/
/*.brands .slider > img:not(.active) {*/
/*  transform: scale(.8);*/
/*}*/
@media screen and (max-width: 991px){
  .brands .slider > img {
  width: 90px;
  height: 90px;
}
}
</style>