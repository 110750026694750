<template>
  <div class="home">
    <div class="slider">
      <PickUpForm :cars="cars"></PickUpForm>
      <div class="slide slide_1"></div>
      <div class="slide slide_2"></div>
    </div>
    <div class="cars">
      <p>Our rentals cars</p>
      <hr class="hr-1">
<!--      <hr class="hr-2">-->
      <div class="cars-inner">
<!--        <h1 v-for="(c, i) in cars" :key="i">{{c}}</h1>-->
<!--        <div class="arrow left" @click="moveSlider($event, true)" v-if="enableCarSlider"></div>-->
<!--        <div class="arrow right" @click="moveSlider($event, false)" v-if="enableCarSlider"></div>-->
        <div class="row">
          <transition-group name="cars" mode="out-in"
          @enter="onEnter"
          @leave="onLeave">
            <template v-for="(car, i) in cars">
              <SliderItem
                  :name="car.name"
                  :image="car.image"
                  :price="car.fixed_price"
                  :key="car.id"
                  v-if="i >= carSliderStartIndex && i <= carSliderFinishIndex"
              ></SliderItem>
            </template>
          </transition-group>
        </div>
        <div class="counter">
          <div v-for="(c, i) in cars" :key="c.id" @click="moveSlider($event, i)"
               :class="{'active': i >= carSliderStartIndex && i <= carSliderFinishIndex}"></div>
        </div>
      </div>
    </div>
    <Brands :brands="brands"></Brands>
    <div class="choose">
      <h1>Why choose us</h1>
      <hr class="hr-1">
      <span>We are №1 in renting high-end vehicles</span>
      <div class="infos">
        <InfoCard
            :title="'Liability insurance to improve your security'"
            :description="'Travel with tranquility with us. We offer civil liability insurance for all our vehicles, and security options as child seat to accommodate your entire family.'"
            :image="'icon-life-ring'">
        </InfoCard>
        <InfoCard
            :title="'Unlimited mileage on all vehicles'"
            :description="'Enjoy your trip without thinking how many kilometers you can drive, because you have unlimited mileage to have a satisfactory experience.'"
            :image="'icon-tachometer'">
        </InfoCard>
        <InfoCard
            :title="'Pick up your car at your desired location'"
            :description="'Choose the desired location on the search form. If you can´t find your desired location, please contact us for custom pricing.'"
            :image="'icon-map-marker'">
        </InfoCard>
      </div>
    </div>
    <div class="get-in-touch">
      <div class="address-phone">
        <div>
          <span class="icon-map-marker icon"></span>
          <div class="information">
            <h4>Address</h4>
            <p>15, Ikarou Street,</p>
            <p>Shop 1, Kato Pafos,</p>
            <p>8041, Paphos,</p>
            <p>Cyprus</p>
          </div>
          <span class="icon-phone icon"></span>
          <div class="information">
            <h4>Phone</h4>
            <p>(+357) 26 933 080</p>
            <p>(+357) 99 938 843 </p>
            <p>[Whatsapp]</p>
          </div>
        </div>
        <h1 style="margin-top: 40px;">Get In Touch</h1>
        <hr>
        <p style="margin-top: 20px;">If you want to request information, please complete and submit</p>
        <p>the form below. You will be soon contacted by our staff.</p>
        <RequestForm></RequestForm>
      </div>
<!--      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d623.5457069258849!2d32.41958802131151!3d34.7595344703933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e707994237986f%3A0xcfeb7fe14903d162!2sA.S+Alpan+Car+Rentals+Ltd.!5e1!3m2!1sen!2s!4v1558968671649!5m2!1sen!2s" style="border: 0px none; pointer-events: none;" allowfullscreen="" width="600" height="600" frameborder="0"></iframe>-->
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1032.8603080678731!2d32.419631690134146!3d34.75968173092041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e706c1e7da7ea5%3A0x24632ae4dfef3af5!2zSWthcm91IDE1LCBQYXBob3MgODA0MSwg0JrQuNC_0YA!5e1!3m2!1sru!2sua!4v1636532751604!5m2!1sru!2sua"  style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
<!--    <img alt="Vue logo" src="../assets/logo.png">-->
<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
// @ is an alias to /src
import {isMobileOnly, isTablet} from 'mobile-device-detect'

// import http from "../services/http";

import SliderItem from "@/components/SliderItem";
import PickUpForm from "@/components/PickUpForm";
import Brands from "@/components/Brands";
import InfoCard from "@/components/InfoCard";
import RequestForm from "@/components/RequestForm";

export default {
  name: 'Home',
  components: {
    SliderItem, PickUpForm, Brands, InfoCard, RequestForm
  },
  data() {
    return {
      cars: [],
      brands: [],
      contacts: {},
      // rowsLength: 0,
      carSliderStartIndex: 0,
      carSliderFinishIndex: 0,
      brandSliderIndex: 0,
      enableCarSlider: false
      // userForm: {
      //   show
      // }
    }
  },
  computed: {

  },
  methods: {
    onEnter(el) {
      console.log('TRANSITION ENTER',el)
      el.style.width = 'auto'
    },
    onLeave(el) {
      console.log('TRANSITION LEAVE',el)
      el.style.width = 0
    },
    onLocationSelect(e) {
      console.log(e);
    },
    moveSlider(e, index) {
      console.log('SLIDE ', e, index)
      if (!isMobileOnly) {
        if (!isTablet) {
          if (index > this.carSliderFinishIndex) {
            if (index - 3 >= 0) {
              this.carSliderFinishIndex = index
              this.carSliderStartIndex = index - 1
            } else {
              this.carSliderStartIndex = 0
              this.carSliderFinishIndex = 1
            }
          } else {
            if (index + 3 <= this.cars.length - 1) {
              this.carSliderStartIndex = index
              this.carSliderFinishIndex = index + 1
            } else {
              this.carSliderStartIndex = this.cars.length - 2
              this.carSliderFinishIndex = this.cars.length - 1
            }
          }
        } else {
          if (index > this.carSliderFinishIndex) {
            if (index - 1 >= 0) {
              this.carSliderFinishIndex = index
              this.carSliderStartIndex = index - 1
            } else {
              this.carSliderStartIndex = 0
              this.carSliderFinishIndex = 1
            }
          } else {
            if (index + 1 <= this.cars.length - 1) {
              this.carSliderStartIndex = index
              this.carSliderFinishIndex = index + 1
            } else {
              this.carSliderStartIndex = this.cars.length - 2
              this.carSliderFinishIndex = this.cars.length - 1
            }
          }
        }
      } else {
        this.carSliderStartIndex = this.carSliderFinishIndex = index;
      }
    }
    // openInputByRef(ref) {
    //   // this.$refs[ref].click();
    //   console.log(ref, this.$refs[ref].value)
    // }
  },
  created() {
    this.$store.dispatch('isReady').then(ready => {
      console.log('READY ', ready)
      if (ready) {
        this.cars = this.$store.getters.siteData.cars
        this.brands = this.$store.getters.siteData.brands
        this.contacts = this.$store.getters.siteData.contacts
        console.log(this.cars)
        if (!isMobileOnly) {
          if (!isTablet) {
            if (this.cars.length >= 4) {
              this.carSliderFinishIndex = 3
              if (this.cars.length > 4) this.enableCarSlider = true
            }
            else {
              this.carSliderFinishIndex = this.cars.length - 1
            }
          } else {
            this.carSliderFinishIndex = this.cars.length - 1
            if (this.cars.length > 2) this.enableCarSlider = true
          }
        } else {
          if (this.cars.length > 1) this.enableCarSlider = true
        }
        this.$forceUpdate()
      }
    })
    console.log('Is mobile: ', isMobileOnly, 'Origin: ', this.$store.getters.locationOrigin)
    // for (let i = 0; i < 4; i++) {
    //   this.cars.push({id: i, name: 'Test car ' + (i + 1), image: '', price: (i + 1) * 28})
    // }
    // setInterval(() => {
    //   if (this.topSliderIndex > 1) {
    //     this.topSliderIndex = 1
    //   } else {
    //     this.topSliderIndex++
    //   }
    // }, 10000)
  },
  mounted() {
    // this.rowsLength = this.cars.length % 4 === 0 ? this.cars.length / 4 : Math.round(this.cars.length / 4)
  }
}
</script>

<style scoped src="../styles/home.css"></style>
