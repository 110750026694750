<template>
  <div class="pick-up-form">
    <span>PICK-UP INFORMATION</span>
    <select @change="pickupAddressInput = $event.target.value === '1'" v-model="form.pickup_info.location">
      <option value="" selected>Pick-up City & Location(Required)*</option>
      <option value="Larnaca Airport">Larnaca Airport</option>
      <option value="Paphos Airport">Paphos Airport</option>
      <option value="Paphos Office">Paphos Office</option>
      <option value="1">Your Hotel(Insert Below)</option>
      <option value="1">Your Address(Insert Below)</option>
    </select>
    <transition name="address">
      <input type="text" v-if="pickupAddressInput" placeholder="Insert Hotel or Address Here" v-model="form.pickup_info.custom">
    </transition>
    <div style="" class="datetime-inputs">
      <label for="pickup_date">
        <span>{{formattedDate(true)}}</span>
        <input type="date" v-model="form.pickup_info.date" :min="dateNow" id="pickup_date" @change="onDateChange($event, true)">
      </label>
      <input type="time" v-model="form.pickup_info.time" id="pickup_time">
    </div>
    <span>RETURN INFORMATION</span>
    <select @change="returnAddressInput = $event.target.value === '1'" v-model="form.return_info.location">
      <option value="" selected>Pick-up City & Location(Required)*</option>
      <option value="Larnaca Airport">Larnaca Airport</option>
      <option value="Paphos Airport">Paphos Airport</option>
      <option value="Paphos Office">Paphos Office</option>
      <option value="1">Your Hotel(Insert Below)</option>
      <option value="1">Your Address(Insert Below)</option>
    </select>
    <transition name="address">
      <input type="text" v-if="returnAddressInput" placeholder="Insert Hotel or Address Here" v-model="form.return_info.custom">
    </transition>
    <div style="" class="datetime-inputs">
      <label for="return_date">
        <span>{{formattedDate(false)}}</span>
        <input type="date" v-model="form.return_info.date" :min="minReturnDate" id="return_date" @change="onDateChange($event)">
      </label>
      <input type="time" v-model="form.return_info.time" id="return_time">
    </div>
    <span>DESIRED CAR</span>
    <select v-model="form.desired_car">
      <option :value="0">Select car</option>
      <option :value="car.id" v-for="(car, i) in cars" :key="i">{{car.name}}</option>
    </select>
    <span>CONTACT DETAILS</span>
    <div class="contact-inputs">
      <input type="text" placeholder="Full Name*" v-model="form.contact_details.full_name">
      <input type="text" placeholder="Email*" v-model="form.contact_details.email">
    </div>
    <div class="contact-inputs">
      <input type="text" placeholder="Phone*" v-model="form.contact_details.phone">
      <textarea  rows="5" placeholder="Additional Comments" v-model="form.contact_details.comments"></textarea>
    </div>
    <button @click="submitForm">Request a Quote!</button>
    <div class="answer" :class="{'error': requestError}" v-if="answer !== ''">{{answer}}</div>
  </div>
</template>

<script>
import http from "../services/http";

export default {
  name: "PickUpForm",
  props: {
    cars: {type: Array, default: () => []}
  },
  data() {
    return {
      pickupAddressInput: false,
      returnAddressInput: false,
      minReturnDate: '',
      timeValue: '',
      dateNow: '',
      answer: '',
      requestError: false,
      form: {
        site_id: 0,
        pickup_info: {
          location: '',
          date: '',
          time: '',
          custom: ''
        },
        return_info: {
          location: '',
          date: '',
          time: '',
          custom: ''
        },
        desired_car: 0,
        contact_details: {
          full_name: '',
          email: '',
          phone: '',
          comments: ''
        }
      }
    }
  },
  computed: {
    formattedDate() {
      return pickUp => {
        let array = []
        const months = ['January','February','March','April','May','June','July','August','September','October','November','December']
        if (pickUp) {
          array = this.form.pickup_info.date.split('-')
        } else {
          array = this.form.return_info.date.split('-')
        }
        if (array.length !== 3) return 'Invalid format';
        if (!months[+array[1] - 1]) return 'Invalid month';
        return `${months[+array[1] - 1]} ${array[2]}, ${array[0]}`;
      }
    }
  },
  created() {
    this.$store.dispatch('isReady').then(() => {
      this.form.site_id = this.$store.getters.siteId
    })
    const date = new Date();
    this.dateNow = this.form.pickup_info.date = this.form.return_info.date = date.getFullYear() + '-' +
        (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1) + '-' + (date.getDate() < 10 ? '0' : '') + date.getDate();
    this.form.pickup_info.time = this.form.return_info.time = date.toTimeString().split(' ')[0].split(':').slice(0, 2).join(':');
    console.log(this.dateNow, this.form.pickup_info.date, this.form.return_info.date, this.form.pickup_info.time, this.form.return_info.time);
    this.$forceUpdate()
  },
  methods: {
    onDateChange(e, pickUp) {
      console.log(e)
      // this.$forceUpdate()
      this.$nextTick().then(() => {
        if (pickUp) {
          this.minReturnDate = this.form.pickup_info.date
          console.log(JSON.stringify(this.form.pickup_info), this.minReturnDate)
        }
        else console.log(JSON.stringify(this.form.return_info))
      })
    },
    detailsValid() {
      return this.form.desired_car > 0 && this.form.contact_details.full_name !== '' && this.form.contact_details.email !== '' &&
          this.form.contact_details.phone !== '';
    },
    checkFormFields() {
      return new Promise(resolve => {
        if (!this.detailsValid() || this.form.pickup_info.location === '' || (this.form.pickup_info.location === '1' &&
            this.form.pickup_info.custom === '') || this.form.return_info.location === ''  || (this.form.return_info.location === '1' &&
            this.form.return_info.custom === '')) {
          resolve(false);
        } else resolve(true)
      })
    },
    submitForm() {
      this.checkFormFields().then(checked => {
        console.log(checked, JSON.stringify(this.form))
        if (checked) {
         // this.$emit('f-submit', this.form)
          http.setNewOrder(this.form).then(r => {
            console.log(r)
            if (r.data.error) {
              this.requestError = true
              this.answer = 'Something went wrong. Please, try again later.'
            } else {
              this.requestError = false
              this.answer = 'Your request has been saved, you will be contacted shortly to discuss the details.'
            }
          }).catch(() => {
            this.requestError = true
            this.answer = 'Something went wrong. Please, try again later.'
          })
        }
      })
    },
  }
}
</script>

<style scoped src="../styles/form.css">

</style>