<template>
  <div id="app">
    <Header></Header>
    <router-view/>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

import http from "./services/http";

export default {
  name: 'App',
  components: {
    Header, Footer
  },
  created() {
    this.$store.dispatch('setLocation', window.location.origin)
    // http.getSiteData(this.$store.getters.locationOrigin).then(r => {
    //   console.log('GET SITE DATA: ', r)
    // })
    this.$store.dispatch('isReady').then(() => {
      const el = document.querySelector('link[rel=icon]')
      // console.log('ICON ELEM ', el)
      if (el) {
        const src = http.getImageSrc(this.$store.getters.siteIcon, 'site-icons')
        el.setAttribute('href', src)
      }
    })
  },
  mounted() {
    console.log('before mount...')
    window.addEventListener('orientationchange', this.handleOrientationChange)
  },
  methods: {
    handleOrientationChange() {
      const orientation = window.screen.orientation
      console.log('orientation ', orientation.type.indexOf('portrait'), ' width ', document.documentElement.clientWidth)
    }
  }
}
</script>

<style src="./styles/common.css"></style>
