import axios from 'axios'

const apiURL = {
    'development': 'http://localhost:55577/',
    'production': 'http://api.rent.booboolab.com/'
};

function newFormData(data) {
    const formData = new FormData();
    if (data) formData.append('Data', typeof data === 'string' ? data : JSON.stringify(data));
    return formData;
}

function getImageSrc(image, key) {
    return `${apiURL[process.env.NODE_ENV]}${key}/${image}`
}

function getCars() {
    return axios.get(`${apiURL[process.env.NODE_ENV]}get/cars`)
}

function setNewOrder(data) {
    const formData = newFormData(data);
    return axios.post(`${apiURL[process.env.NODE_ENV]}rent-order`, formData, {responseType: 'json'})
}

function setOrderWithRegistration(order, client) {
    const formData = newFormData();
    formData.append('Order', JSON.stringify(order))
    if (client) formData.append('Client', JSON.stringify(client))
    return axios.post(`${apiURL[process.env.NODE_ENV]}order-reg`, formData, {responseType: 'json'})
}

function setNewMessage(data) {
    const formData = newFormData(data);
    return axios.post(`${apiURL[process.env.NODE_ENV]}new-message`, formData, {responseType: 'json'})
}

function getSiteData(id) {
    const formData = newFormData(id)
    return axios.post(`${apiURL[process.env.NODE_ENV]}get-site-data`, formData, {responseType: 'json'})
}

const http = {
    setNewOrder: setNewOrder,
    setOrderWithRegistration: setOrderWithRegistration,
    setNewMessage: setNewMessage,

    getImageSrc: getImageSrc,

    getCars: getCars,
    getSiteData: getSiteData
}

export default http
