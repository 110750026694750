<template>
  <div class="head-container">
    <div class="header" :class="{'hidden': !showHeader}">
      <div class="left-links">
<!--        <img src="../assets/whatsapp.svg" alt="whatsapp" class="link-img">-->
        <span class="span-icon icon-whatsapp"></span>
        <a v-if="!isMobile" :href="links.whatsapp" target="_blank">Whatsapp</a>
        <span class="span-icon icon-phone"></span>
        <a :href="'tel:' + links.phone">{{ links.phone }}</a>
      </div>
      <div class="right-links">
        <span v-if="!isMobile">Follow us on:&nbsp;</span>
<!--        <img src="../assets/facebook.svg" alt=""><img src="../assets/instagram.svg" alt="">-->
        <a :href="links.facebook" class="icon-facebook fb" target="_blank"></a>
        <a :href="links.instagram" class="icon-instagram inst" target="_blank"></a>
      </div>
    </div>
    <div class="navbar" :class="{'scrolled': !showHeader}">
      <img src="../assets/site_logo.png" alt="">
      <div class="nav" v-if="!isMobile">
        <div @click="onClick">
          <router-link to="/">Home</router-link>
        </div>
        <div @click="onClick">
          <router-link to="/about">About Us</router-link>
        </div>
        <div @click="onClick">
          <router-link to="/contact">Contact</router-link><div class="arrow"></div>
        </div>
        <div @click="onClick">
          <router-link to="/our-cars">Our cars</router-link><div class="arrow"></div>
        </div>
      </div>
      <template v-else>
        <div class="menu-btn" @click="menuOpened = !menuOpened">
          <span :class="!menuOpened ? 'icon-bars' : 'icon-times'"></span>
        </div>
        <div class="mobile-menu" :class="{'opened': menuOpened, 'hidden': !showHeader}">
          <div @click="onClick">
            <router-link to="/">Home</router-link>
          </div>
          <div @click="onClick">
            <router-link to="/about">About Us</router-link>
          </div>
          <div @click="onClick">
            <router-link to="/contact">Contact</router-link><div class="arrow"></div>
          </div>
          <div @click="onClick">
            <router-link to="/our-cars">Our cars</router-link><div class="arrow"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {isMobile, isTablet} from 'mobile-device-detect';

export default {
  name: "Header",
  data() {
    return {
      maxScrollToRemoveHeader: 50,
      showHeader: true,
      links: {
        whatsapp: '',
        phone: '',
        facebook: '',
        instagram: ''
      },
      isMobile: isMobile,
      isTablet: isTablet,
      menuOpened: false
    }
  },
  methods: {
    onScroll() {
      const scrollY = window.scrollY
      if (scrollY <= this.maxScrollToRemoveHeader) {
        if (!this.showHeader) this.showHeader = true
      } else {
        if (this.showHeader) this.showHeader = false
      }
    },
    onClick() {
      // alert('click')
      window.scrollTo(0,0)
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  created() {
    this.$store.dispatch('isReady').then(ready => {
      if (ready) {
        // console.log(this.$store.getters.siteData)
        const contacts = this.$store.getters.siteData.contacts
        this.links.whatsapp = contacts.whatsapp
        this.links.phone = contacts.phone
        this.links.facebook = contacts.facebook
        this.links.instagram = contacts.instagram
      }
    })
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style scoped src="../styles/header.css">

</style>