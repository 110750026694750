<template>
  <div class="card" :class="{'hover-out': !hover}" @mouseover="hover = true" @mouseleave="hover = false">
    <!--      <img :src="image" alt="">-->
    <img :src="getImageSrc(image, 'cars')" alt="">
    <span class="name">{{name}}</span>
    <hr>
    <div class="price">
      <span>DAY FROM</span>
      <div><b>&#x20ac;</b><i>{{price[siteId]}}</i></div>
    </div>
  </div>
</template>

<script>
import http from "../services/http";

export default {
  name: "Card",
  props: {
    name: {type: String, required: true},
    image: {type: String, required: true},
    price: {type: Object, required: true},
    // show: {type: Boolean, required: true}
  },
  data() {
    return {
      hover: false,
      siteId: 0
    }
  },
  created() {
    console.log(this.name, this.image, this.price)
    this.$store.dispatch('isReady').then(() => {
      this.siteId = this.$store.getters.siteId
    })
  },
  methods: {
    getImageSrc(image, key) {
      return http.getImageSrc(image, key)
    }
  }
}
</script>

<style scoped src="../styles/card.css"></style>