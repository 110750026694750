<template>
  <div class="req-form">
    <input type="text" placeholder="Name" v-model="name">
    <input type="text" placeholder="Email" v-model="email">
    <input type="text" placeholder="Phone" v-model="phone">
    <input type="text" placeholder="Subject" v-model="subject">
    <textarea name="" id="" cols="30" rows="10" placeholder="Message" v-model="message" spellcheck="false"></textarea>
    <button @click="sendRequest">Send</button>
  </div>
</template>

<script>
import http from "../services/http";

export default {
  name: "RequestForm",
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: ''
    }
  },
  methods: {
    sendRequest() {
      const request = {
        site_id: this.$store.getters.siteId,
        name: this.name,
        email: this.email,
        phone: this.phone,
        subject: this.subject,
        message: this.message
      }
      console.log(JSON.stringify(request))
      http.setNewMessage(request).then(r => {
        console.log(r)
      })
    }
  }
}
</script>

<style scoped>
  .req-form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
  }
  .req-form > * {
    margin-top: 10px;
    font-size: 1rem;
  }
  input {
    min-width: 45%;
    max-width: 45%;
    height: 2rem;
  }
  textarea {
    width: 100%;
    resize: vertical;
    max-height: 7.4rem;
  }
  input, textarea, button {
    /*border: 1px solid darkgray;*/
    border-radius: 5px;
  }
  button {
    width: 100%;
    background-color: rgba(0,0,0,0.2);
    padding: 10px;
    color: rgba(0,0,0,0.6);
  }
  @media screen and (max-width: 991px) {
    .req-form {
      flex-direction: column;
      flex-wrap: nowrap;
    }
    input, textarea, button {
      min-width: 95%;
      max-width: 95%;
      margin: auto;
    }
  }
</style>