<template>
  <div class="info-card">
<!--    <slot>-->

<!--    </slot>-->
    <div class="img-div">
      <span :class="image"></span>
    </div>
    <span>{{title}}</span>
    <hr>
    <p>{{description}}</p>
  </div>
</template>

<script>
export default {
  name: "InfoCard",
  props: {
    image: {type: String, required: true},
    title: {type: String, required: true},
    description: {type: String, required: true}
  }
}
</script>

<style scoped>
  .info-card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /*background-color: #42b983;*/
    background-color: #ffffff;
    padding: 20px 10px;
    max-width: calc(30% - 20px);
    min-width: calc(30% - 20px);
    /*min-height: 10rem;*/
    transition: max-width .5s, min-width .5s, left .5s;
    position: relative;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
  }
  .info-card > *:not(hr):not(.img-div) {
    width: 100%;
  }
  .info-card > span {
    font-size: 1.2rem;
    margin-top: 20px;
  }
  .img-div {
    width: 5rem;
    height: 5rem;
    background-color: orange;
    margin-left: calc(50% - 2.5rem);
    border-radius: 50%;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    transition: all .8s ease;
    position: relative;
  }
  .img-div span::before {
    width: 2.85rem;
    height: 2.85rem;
    color: #ffffff;
    font-size: 2.85rem;
    margin: auto;
    /*transform: translate(-50%,-50%);*/
    position: absolute;
    top: calc(50% - 1.425rem);
    left: calc(50% - 1.425rem);
    border-radius: 50%;
  }
  .img-div:hover {
    transform: rotateY(180deg) scale(1.1);
    transition: all 1s ease;
  }
  .img-div:hover span {
    transform: scale(1.1);
  }
  hr {
    height: 2px;
    color: #ffce07;
    background-color: #ffce07;
    border: none;
    width: 20%;
  }
  p {
    /*margin: 0;*/
    margin-top: 50px;
    font-size: 0.8rem;
  }
  @media screen and (max-width: 767px) {
    .info-card {
      max-width: calc(90% - 20px);
      min-width: calc(90% - 20px);
    }
  }
</style>